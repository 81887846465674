import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

class InvestorArea extends Component<{}, {}> {
  render() {
    return (
      <div className="envestor-area pd-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="section-title">
                <h6 className="subtitle subtitle-thumb">Envestor</h6>
                <h2 className="title">Top Invester of E-Banking</h2>
                <p>
                We provide the best investment option.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <OwlCarousel className="envestor-slider" loop margin={10} items={4} nav={false} dots={false}>
                <div className="item">
                  <div className="single-envestor text-center">
                    <img src="content/assets/store/investers/01.jpg" alt="img" />
                    <h6 className="name">Rachelle Russell</h6>
                    <p>Top Invester</p>
                    <ul className="social-area">
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-facebook initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-twitter initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-linkedin initiate-scripts" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="single-envestor text-center">
                    <img src="content/assets/store/investers/02.jpg" alt="img" />
                    <h6 className="name">Joe Nguyen</h6>
                    <p>Top Invester</p>
                    <ul className="social-area">
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-facebook  initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-twitter initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-linkedin initiate-scripts" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="single-envestor text-center">
                    <img src="content/assets/store/investers/03.jpg" alt="img" />
                    <h6 className="name">Hendrix Meyers</h6>
                    <p>Top Invester</p>
                    <ul className="social-area">
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-facebook initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-twitter initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-linkedin initiate-scripts" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="item">
                  <div className="single-envestor text-center">
                    <img src="content/assets/store/investers/04.jpg" alt="img" />
                    <h6 className="name">Kim Martins</h6>
                    <p>Top Invester</p>
                    <ul className="social-area">
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-facebook initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-twitter initiate-scripts" />
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services'}>
                          <i className="fa fa-linkedin initiate-scripts" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvestorArea;
